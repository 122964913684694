import "./Utility.scss";
import React from "react";
import DefaultContent from "./Default-Content.js";
import PrimoContent from "./Primo-Content.js";

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

const Utility = () => {
  if (activeEnv === "primo" || activeEnv === "stagingprimo") {
    return <PrimoContent />
  } else {
    return <DefaultContent />
  }
}

export default Utility;
