import React from "react";
import "../styles/screen.scss";
import HeaderSection from "../components/HeaderSection/";
import Utility from "../components/Utility/";

export default function Terms() {
  return <main className="site-wrapper">
    <HeaderSection />
    <Utility />
  </main>
}
