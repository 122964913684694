import React from "react";

const PrimoContent = () => (
  <section className="mtmb-utility">
    <div className="mtmb-utility__inner">
        <div className="mtmb-utility__header">
          <h1 className="mtmb-header-2">Official Rules for the ST~GERMAIN® Make the Moment Bloom PRIMO Sweepstakes</h1>
          <span className="mtmb-fancy-spacer"></span>
          <p className="mtmb-body">No purchase is required to enter.</p>
        </div>
        <div className="rich-text">
          <h3 className="mtmb-header-3">1. ELIGIBILITY:</h3>
          <p className="mtmb-large-body">Primo Sweepstakes open only to current employees of Bacardi U.S.A., Inc. (“BUSA”).  Winners will be chosen at random and notified by email, using the contact information on the entry form. Primo Sweepstakes ends on 11:59PM (ET) on February 28, 2021.</p>
          <h3 className="mtmb-header-3">2. ENTRY PERIOD:</h3>
          <p className="mtmb-large-body">The Entry Period begins at 9:00 AM (ET) on January 18, 2021 and ends at 11:59 PM (ET) on February 28, 2021(the “Entry Period”).</p>
          <h3 className="mtmb-header-3">3. HOW TO ENTER:</h3>
          <p className="mtmb-large-body">Visit <a href="mtmbprimo.stgermain.fr">mtmbprimo.stgermain.fr</a> and complete the entry form to receive one (1) entry. There is a limit of one (1) entry per person.</p>
          <h3 className="mtmb-header-3">4. DRAWING:</h3>
          <p className="mtmb-large-body">On or about March 1, 2021(“Random Drawing Date”)BUSA will select two (2) potential winners from all entries received by the Random Drawing Date. The odds of winning depend on the number of eligible entries received. BUSA will attempt to notify the potential winners within five (5) business days of the drawing.</p>
          <h3 className="mtmb-header-3">6. Prizes:</h3>
          <p className="mtmb-large-body">Each winner will receive a ST~GERMAIN® Gift Box(ARV $100.00), including a set of two Gold Rim Glasses, the How to Spritz French Fluently book, one Large Carafe, a set of two metal stirrers, a set of four steel spoon straws, and a Phillipe Ashley Chocolate Box. Prizes are not transferable and must be accepted as awarded. BUSA reserves the right to substitute a prize with another prize of equal or greater value if the prize is not available for any reason as determined by the Sponsor in its sole discretion. The winners a reresponsible for taxes and all other costs and expenses not listed above. If a winner is not currently employed by BUSA, he/she will not be eligible to receive the prize.</p>
        </div>
    </div>
  </section>
)

export default PrimoContent;
