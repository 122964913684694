import "./HeaderSection.scss";
import logo from "./assets/logo.png";
import React from "react";

const HeaderSection = () => (
  <header className="mtmb-header">
    <a href="https://www.stgermain.fr/" target="_blank"><img src={logo} alt="ST~GERMAIN Logo" /></a>
    <a href="https://www.stgermain.fr/" className="continue-button">Continue to site ></a>
  </header>
);

export default HeaderSection;
